var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mack-offer"},[_c('div',{staticClass:"content"},[_c('div',{class:['file-list', _vm.hide ? 'on' : '']},[_c('el-form',{ref:"tablelists",staticClass:"tablelists-form",attrs:{"model":_vm.forms,"label-width":"100px","size":"small"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"size":"small","border":"","data":_vm.forms.yzAutoPriceItemList}},[_c('el-table-column',{attrs:{"width":"200","prop":"fileName","align":"center","label":"零件名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzAutoPriceItemList." + (scope.$index) + ".fileName"),"rules":{
                  required: true,
                  message: '请输入零件名称',
                  trigger: 'blur',
                }}},[_c('el-input',{attrs:{"placeholder":"请输入零件名称"},model:{value:(scope.row.fileName),callback:function ($$v) {_vm.$set(scope.row, "fileName", $$v)},expression:"scope.row.fileName"}})],1)]}}])}),_c('el-table-column',{attrs:{"width":"150","prop":"technology","align":"center","label":"工艺"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzAutoPriceItemList." + (scope.$index) + ".technology"),"rules":{
                  required: true,
                  message: '请选择工艺',
                  trigger: 'change',
                }}},[_c('el-select',{attrs:{"placeholder":"请选择","value-key":"dictValue"},on:{"change":_vm.technologyChange},model:{value:(scope.row.technology),callback:function ($$v) {_vm.$set(scope.row, "technology", $$v)},expression:"scope.row.technology"}},_vm._l((scope.row.gyList),function(item){return _c('el-option',{key:item.dictValue,attrs:{"label":item.dictLabel,"value":Object.assign({}, item,
                      {yzAutoPriceId: scope.row.yzAutoPriceId})}})}),1)],1)]}}])}),_c('el-table-column',{attrs:{"width":"150","prop":"materialTexture","align":"center","label":"材质"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzAutoPriceItemList." + (scope.$index) + ".materialTexture"),"rules":{
                  required: true,
                  message: '请选择材质',
                  trigger: 'change',
                }}},[_c('el-select',{attrs:{"value-key":"dictValue","disabled":!scope.row.czList.length,"placeholder":"请选择"},model:{value:(scope.row.materialTexture),callback:function ($$v) {_vm.$set(scope.row, "materialTexture", $$v)},expression:"scope.row.materialTexture"}},_vm._l((scope.row.czList),function(item){return _c('el-option',{key:item.dictValue,attrs:{"label":item.dictLabel,"value":Object.assign({}, item,
                      {yzAutoPriceId: scope.row.yzAutoPriceId})}})}),1)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"surfaceTreatment","align":"center","label":"表面处理","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzAutoPriceItemList." + (scope.$index) + ".surfaceTreatment"),"rules":{
                  required: true,
                  message: '请选择表面处理',
                  trigger: 'change',
                }}},[_c('el-select',{attrs:{"disabled":!scope.row.bmList.length,"placeholder":"请选择","value-key":"dictValue"},model:{value:(scope.row.surfaceTreatment),callback:function ($$v) {_vm.$set(scope.row, "surfaceTreatment", $$v)},expression:"scope.row.surfaceTreatment"}},_vm._l((scope.row.bmList),function(item){return _c('el-option',{key:item.dictValue,attrs:{"label":item.dictLabel,"value":Object.assign({}, item,
                      {yzAutoPriceId: scope.row.yzAutoPriceId})}})}),1)],1)]}}])}),_c('el-table-column',{attrs:{"width":"80","prop":"color","align":"center","label":"颜色"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzAutoPriceItemList." + (scope.$index) + ".color"),"rules":{
                  required: true,
                  message: '请选择颜色',
                  trigger: 'change',
                }}},[_c('el-color-picker',{staticStyle:{"position":"relative","top":"4px"},model:{value:(scope.row.color),callback:function ($$v) {_vm.$set(scope.row, "color", $$v)},expression:"scope.row.color"}})],1)]}}])}),_c('el-table-column',{attrs:{"width":"150","prop":"quantity","align":"center","label":"数量"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzAutoPriceItemList." + (scope.$index) + ".quantity"),"rules":{
                  required: true,
                  message: '请输入数量',
                  trigger: 'blur',
                }}},[_c('el-input',{attrs:{"type":"number","placeholder":"请输入数量"},model:{value:(scope.row.quantity),callback:function ($$v) {_vm.$set(scope.row, "quantity", $$v)},expression:"scope.row.quantity"}},[_c('span',{staticClass:"unit",attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("件")])])],1)]}}])}),_c('el-table-column',{attrs:{"width":"150","prop":"desciption","align":"center","label":"备注"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzAutoPriceItemList." + (scope.$index) + ".desciption")}},[_c('el-input',{attrs:{"placeholder":"请输入备注"},model:{value:(scope.row.desciption),callback:function ($$v) {_vm.$set(scope.row, "desciption", $$v)},expression:"scope.row.desciption"}})],1)]}}])}),_c('el-table-column',{attrs:{"width":"168","align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.del(scope.row)}}},[_vm._v(" 删除 ")])],1)]}}])})],1)],1),_c('div',{staticClass:"btn"},[_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-circle-plus-outline"},on:{"click":function($event){return _vm.add()}}},[_vm._v("添加新零件")])],1),_c('div',{staticClass:"form-main"},[_c('el-form',{ref:"forms",staticClass:"forms-dynamic",attrs:{"model":_vm.forms,"label-width":"110px","label-suffix":"：","size":"small"}},[_c('el-row',{attrs:{"gutter":10}},[(!_vm.$store.state.userInfo.userCode)?_c('el-col',{attrs:{"span":_vm.isWap?22:7}},[_c('el-form-item',{attrs:{"prop":"phone","label":"手机号码","rules":[
                  {
                    required: !_vm.$store.state.userInfo.userCode,
                    validator: _vm.validatorPhone,
                    trigger: 'blur',
                  } ]}},[_c('el-input',{attrs:{"type":"number","placeholder":"请输入手机号码"},model:{value:(_vm.forms.phone),callback:function ($$v) {_vm.$set(_vm.forms, "phone", $$v)},expression:"forms.phone"}})],1)],1):_vm._e(),(!_vm.$store.state.userInfo.userCode)?_c('el-col',{attrs:{"span":_vm.isWap?22:7}},[_c('el-form-item',{attrs:{"prop":"desciption","label":"短信验证码","rules":[
                  {
                    required: !_vm.$store.state.userInfo.userCode,
                    message: '请输入验证码',
                    trigger: 'blur',
                  } ]}},[_c('el-row',[_c('el-col',{attrs:{"span":14}},[_c('el-input',{attrs:{"placeholder":"请输入验证码"},model:{value:(_vm.forms.desciption),callback:function ($$v) {_vm.$set(_vm.forms, "desciption", $$v)},expression:"forms.desciption"}})],1),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":9,"offset":1}},[_c('el-button',{attrs:{"type":"primary","loading":_vm.codeLoad,"disabled":_vm.codeText != '发送验证码' &&
                        _vm.codeText != '重新发送验证码'},on:{"click":_vm.getCode}},[_vm._v(_vm._s(_vm.codeText))])],1)],1)],1)],1):_vm._e(),_c('el-col',{attrs:{"span":!_vm.$store.state.userInfo.userCode ? _vm.isWap?24:3 : 24}},[_c('div',{staticClass:"go-btn"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.btnLoad,"disabled":_vm.forms.yzAutoPriceItemList == 0,"size":"small"},on:{"click":_vm.submitForm}},[_vm._v("获取报价")])],1),_c('p',{staticClass:"hint"},[_vm._v(" 如有工艺不明确请 "),_c('a',{attrs:{"href":"https://www.yizao2025.com/f/contactKefu","target":"_blank"}},[_vm._v("在线咨询制造专家")])])])],1)],1)],1)],1),_c('div',{staticClass:"down",on:{"click":function($event){_vm.hide = !_vm.hide}}},[_vm._v(" "+_vm._s(_vm.hide ? "展开报价工具" : "收起报价工具")+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }