<template>
  <div class="make">
    <div :class="['make-top', isShow ? 'on' : '']">
      <div class="swiper-container" id="makeBanner" v-if="!isShow">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in bannerList" :key="item.id">
            <el-image
              :src="item.addFiles"
              :alt="item.adName"
              fit="cover"
            ></el-image>
          </div>
        </div>
        <div
          class="swiper-pagination swiper-pagination1"
          slot="pagination"
        ></div>
        <div class="btns">
          <div @click="bannerClick">
            快速报价
            <img src="@/assets/images/public/pointer.gif" alt="">
          </div>
          <div @click="up">发布需求</div>
        </div>
      </div>
      <div :class="['content', !isShow ? 'on' : '']">
        <!-- <iframe src="https://3dview.yizao2025.com/" frameborder="0"></iframe> -->
        <make3d ref="make" @parsing="parsing" @up="up" @success="upSuccess" />
      </div>
    </div>
    <offer v-show="isShowOffer" ref="offers" @submit="offerSubmit" />
    <div class="make-content">
      <div class="content flex_b">
        <div class="l">
          <div class="classify">
            <div class="servers-classify">
              <h3><i class="iconfont icon-fenlei"></i> 服务分类<img src="@/assets/images/public/pointer.gif" alt=""></h3>
              <div class="classify-list">
                <div class="item" v-for="item in classifyList" :key="item.id">
                  <div class="item-title" @click="toSupplier(item)">
                    <i
                      :class="[
                        'iconfont',
                        item.categoryIcon || 'icon-duomeitiicon-',
                      ]"
                    ></i>
                    {{ item.name }}
                  </div>
                  <div class="item-list" v-if="item.list.length">
                    <div class="list-main">
                      <div
                        class="items"
                        @click="toSupplier(item, items)"
                        v-for="items in item.list"
                        :key="items.id"
                      >
                        {{ items.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="else">
              <a
                class="item"
                :href="item.url"
                v-for="item in menu2List"
                :key="item.id"
                >{{ item.title }}</a
              >
              <span class="item" @click="up">发布需求</span>
            </div>
          </div>
          <div class="ad-banner">
            <div class="swiper-container" id="adBanner">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in adList" :key="item.id">
                  <a :href="item.adUrl" target="_blank">
                    <el-image
                      :src="item.addFiles"
                      :alt="item.adName"
                      fit="cover"
                    ></el-image>
                  </a>
                </div>
              </div>
              <div
                class="swiper-pagination swiper-pagination2"
                slot="pagination"
              ></div>
            </div>
          </div>
        </div>
        <div class="r">
          <h3>欢迎来到易造云工厂</h3>
          <div class="btns">
            <div class="login" v-if="!userInfo.userCode">
              <span class="el-icon-user-solid"></span>
              <a :href="$store.state.loginUrl">登录</a> /
              <a :href="$store.state.registerUrl">注册</a>
            </div>
            <div class="login" v-if="userInfo.userCode">
              <span class="el-icon-user-solid"></span>
              <a href=" /a/login?yzType=2">{{
                userInfo.roleType == 1 ? "客户中心" : "工厂中心"
              }}</a>
            </div>
            <el-button type="primary" @click="goSupplier"
              >找工厂/报价</el-button
            >
          </div>
          <div class="tabs">
            <el-tabs v-model="orderActive" type="card">
              <el-tab-pane label="我的订单" name="1"></el-tab-pane>
              <el-tab-pane label="我的报价" name="2"></el-tab-pane>
            </el-tabs>
            <div class="list order-list" v-if="orderActive == 1">
              <a
                :href="item.url"
                class="item"
                v-for="item in orderList"
                :key="item.id"
              >
                <i :class="['iconfont', item.icon]"></i>
                <p>{{ item.title }}</p>
              </a>
            </div>
            <div class="list order-list" v-if="orderActive == 2">
              <a
                :href="item.url"
                class="item"
                v-for="item in orderList2"
                :key="item.id"
              >
                <i :class="['iconfont', item.icon]"></i>
                <p>{{ item.title }}</p>
              </a>
            </div>
          </div>
          <div class="tabs">
            <el-tabs v-model="menuActive" type="card">
              <el-tab-pane label="采购常用" name="1"></el-tab-pane>
              <el-tab-pane label="设计常用" name="2"></el-tab-pane>
            </el-tabs>
            <div class="list" v-if="menuActive == 1">
              <a
                :href="item.url"
                class="item text"
                v-for="item in menuList"
                :key="item.id"
              >
                <p>{{ item.title }}</p>
              </a>
            </div>
            <div class="list" v-if="menuActive == 2">
              <a
                :href="item.url"
                class="item text"
                v-for="item in menuList2"
                :key="item.id"
              >
                <p>{{ item.title }}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="b-ad flex">
          <template v-for="(item, index) in minAdList">
            <a
              :href="item.adUrl"
              v-if="index < 4"
              class="item"
              target="_blank"
              :key="index"
            >
              <el-image :src="item.addFiles" fit="cover"></el-image>
            </a>
          </template>
        </div>
      </div>
    </div>
    <div class="flow">
      <div class="content">
        <div class="list flex_b flex_align_c">
          <div
            class="item flex_b flex_align_c"
            v-for="(item, index) in flowList"
            :key="item.id"
          >
            <div class="num flex_c flex_align_c">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="info">
              <h3>{{ item.title }}</h3>
              <p v-for="items in item.label" :key="items">{{ items }}</p>
            </div>
            <div class="next">------->></div>
          </div>
        </div>
      </div>
    </div>
    <hot mode="servers" itemLen="4" />
    <hot mode="supplier" itemLen="4" />
    <up-dialog :visible.sync="upVisible" />
  </div>
</template>
<script>
import Swiper from "@/assets/swiper/js/swiper.min.js";
import "@/assets/swiper/css/swiper.min.css";
import make3d from "@/components/make3d/";
import hot from "@/components/servers/hot";
import offer from "@/components/offer/index";
import upDialog from "@/components/servers/up-dialog";
export default {
  components: { make3d, hot, upDialog, offer },
  data() {
    return {
      upVisible: false,
      serversTabList: [],
      flowList: [
        {
          id: 1,
          title: "需求发布与审核",
          label: ["发布需求", "平台工程审核需求"],
        },
        { id: 2, title: "供应商报价", label: ["平台匹配供应商", "查看报价"] },
        {
          id: 3,
          title: "在线下单支付",
          label: ["确认中标", "在线下单", "进入生产"],
        },
        { id: 4, title: "确认收货", label: ["跟进订单生产", "收货验收"] },
      ],
      menuActive: "1",
      menuList: [
        { id: 1, title: "批量订购", url: "" },
        { id: 2, title: "品牌一览", url: "" },
        { id: 3, title: "账务平台", url: "" },
        { id: 4, title: "型号转换管理", url: "" },
        { id: 5, title: "使用指南", url: "" },
        { id: 6, title: "订购", url: "" },
        { id: 7, title: "品牌一览", url: "" },
      ],
      menuList2: [
        { id: 1, title: "目录与软件", url: "" },
        { id: 2, title: "申请设计支援", url: "" },
        { id: 3, title: "软件技术之窗", url: "" },
        { id: 4, title: "案例库CAD", url: "" },
        { id: 5, title: "下载记录", url: "" },
        { id: 6, title: "技术咨询", url: "" },
      ],
      orderList: [
        { id: 1, icon: "icon-daifukuan", title: "待付款", url: "" },
        { id: 2, icon: "icon-daifahuo1", title: "生产进程", url: "" },
        { id: 3, icon: "icon-yifahuo", title: "已发货", url: "" },
        { id: 4, icon: "icon-dingdan", title: "全部订单", url: "" },
      ],
      orderList2: [
        { id: 1, icon: "icon-daifahuo1", title: "待确认", url: "" },
        { id: 2, icon: "icon-dingdan", title: "全部报价", url: "" },
      ],
      orderActive: "1",
      bannerList: [],
      bannerList2: [],
      isShow: false,
      adList: [],
      minAdList: [],
      menu2List: [
        { id: 1, title: "招标大厅", url: "/service/list" },
        { id: 2, title: "热门服务", url: "/servers/list" },
        { id: 3, title: "服务商列表", url: "/supplier/list" },
        // { id: 4, title: "设计师支援", url: "" },
        // { id: 5, title: "供应商合作", url: "" },
      ],
      classifyList: [],
      yzShopMainList: [],
      yzShopInfoRecommendList: [],
      serversTabActive: "",
      form: {},
      isShowOffer: false,
      offerId: "",
    };
  },
  methods: {
    offerSubmit(form) {
      console.log(form);
      this.form = form;
      this.$api
        .post("/api/v1/saveAutoPrice", this.$common.getFormData(form))
        .then((res) => {
          if (res.code == 1) {
            this.offerId = res.data.id;
            this.$refs.make.submit();
          }
        });
    },
    parsing(list) {
      this.isShowOffer = true;
      this.$refs.offers.appendList(list);
    },
    goSave() {
      this.$refs.offers.over();
      this.$refs.make.reset();
      this.$message({
        message: "提交报价成功！",
        type: "success",
        duration: 1500,
      });
    },
    goAbout(files, i = 0) {
      this.$api
        .post(
          "/api/v1/uploadAutoPriceFile",
          this.$common.getFormData({
            fileUploadId: files[i].fileUploadId,
            id: this.offerId,
          })
        )
        .then((res) => {
          debugger;
          if (res.code == 1) {
            if (files.length - 1 == i) {
              this.goSave();
            } else {
              i++;
              this.goAbout(files, i);
            }
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 1500,
            });
            this.btnLoading = false;
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
            duration: 1500,
          });
          this.btnLoading = false;
        });
    },
    upSuccess(files) {
      if (files) {
        this.goAbout(files);
      }
    },
    goSupplier() {
      location.href = "/supplier/list";
    },
    goChat() {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = "https://www.yizao2025.com/f/contactKefu";
    },
    up() {
      this.upVisible = true;
    },
    toSupplier(item, items) {
      location.href = `/servers/list?pid=${item.id}${
        items ? `&id=${items.id}` : ""
      }`;
    },
    serversTabChange(item) {
      this.serversTabActive = item.dictValue;
      this.getYzShopMainList();
    },
    bannerClick(item) {
      this.isShow = true;
    },
    async getBannerData() {
      await this.$sapi.get("/api/v1/getAdList-3.json").then((res) => {
        this.bannerList = res.list.map((item) => {
          // item.addFiles = item.addFiles.replace('http://yizao.oss-cn-qingdao.aliyuncs.com/','https://static.yizao2025.com/')
          return item;
        });
        this.$nextTick(() => {
          new Swiper("#makeBanner", {
            autoplay: {
              delay: 5000,
              stopOnLastSlide: false,
              disableOnInteraction: true,
            },
            observer: true,
            observeParents: true,
            pagination: {
              el: ".swiper-pagination1",
              clickable: true,
            },
          });
        });
      });
      //首页banner
      await this.$sapi.get("/api/v1/getAdList-1.json").then((res) => {
        this.adList = res.list;
        this.$nextTick(() => {
          new Swiper("#adBanner", {
            autoplay: {
              delay: 5000,
              stopOnLastSlide: false,
              disableOnInteraction: true,
            },
            observer: true,
            observeParents: true,
            pagination: {
              el: ".swiper-pagination2",
              clickable: true,
            },
          });
        });
      });
    },
    getClassifyData() {
      this.$api.get("/api/v1/getYzTaskCategoryList").then((res) => {
        let _json = {};
        let pList = [];
        let list = res.filter((item) => {
          if (item.parentCode == "0") {
            _json[item.id] = [];
            pList.push(item);
          }
          return item.parentCode != "0";
        });
        list.forEach((item) => {
          if (_json[item.parentCode]) _json[item.parentCode].push(item);
        });
        this.classifyList = pList.map((item) => {
          if (_json[item.id]) {
            item.list = _json[item.id];
          }
          return item;
        });
        this.classifyList = this.classifyList.filter((item, index) => {
          return index < 10;
        });
        this.classifyList.push({
          id: 9999,
          categoryIcon: "icon-qita",
          name: "其他",
          list: [],
        });
        console.log(this.classifyList);
      });
    },
    getYzShopMainList() {
      this.$api
        .get("/api/v1/getYzShopMainList", {
          params: {
            pageNo: 1,
            pageSize: 12,
          },
        })
        .then((res) => {
          this.yzShopMainList = (res.list || res.data.list).map((item) => {
            item.yzShopMainNeedTags = item.yzShopMainNeedTags
              ? item.yzShopMainNeedTags.split(",")
              : [];
            return item;
          });
        });
    },
    getDict() {
      this.$sapi
        .get("/api/v1/getDictDataList-yz_index_recommend_service.json")
        .then((res) => {
          this.serversTabList = res;
        });
    },
    getYzShopInfoRecommendList() {
      this.$api
        .get("/api/v1/getYzShopInfoRecommendList", {
          params: {
            pageNo: 1,
            pageSize: 12,
          },
        })
        .then((res) => {
          this.yzShopInfoRecommendList = res.list || res.data.list;
        });
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  mounted() {
    if (this.$route.query.up) {
      this.up();
    }
    this.$parent.finish();
    //3d预览banner
    this.getBannerData();
    //获取服务分类
    this.getClassifyData();
    //获取推荐服务
    this.getYzShopMainList();
    //字典
    this.getDict();
    //推荐服务商
    this.getYzShopInfoRecommendList();
    //小类目banner
    this.$sapi.get("/api/v1/getAdList-2.json").then((res) => {
      this.minAdList =
        res.list.length >= 4 ? res.list : [...res.list, ...res.list];
    });
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/make.scss";
</style>
